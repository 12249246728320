// Include any default variable overrides here (though functions won't be available)

$primary: #7c4bd8;

@import "../../../node_modules/bootstrap/scss/bootstrap";

// Then add additional custom code here
body {
    //padding-top: 5rem;
}

/* ARTPICS STYLES */

/* HEADER */
.app-header {
  line-height: 1;
//   border-bottom: 1px solid var(--bs-border-color-translucent);
  border-bottom: 1px solid #e5e5e5;
}

.app-header-logo {
  font-size: 1.5rem;
  text-decoration: none;
}

.form-inputs{
  position:relative;
}
.form-inputs .form-control{
  height:45px; 
}

.form-inputs .form-control:focus{
  box-shadow:none;
  border:1px solid #767676;
}

.form-inputs .form-control:focus + button i{
  color: #767676;
}

.form-inputs .app-btn-search{
  color: var(--bs-border-color-translucent);
  position:absolute;
  right:50px;
  top:4px;
}

.app-btn-filter{
  font-size: 1.4em;
}

.dropdown-menu li {
  position: relative;
  }
  .dropdown-menu .dropdown-submenu {
  display: none;
  position: absolute;
  left: 100%;
  top: -7px;
  }
  .dropdown-menu .dropdown-submenu-left {
  right: 100%;
  left: auto;
  top: -7px;
  }
  .dropdown-menu > li:hover > .dropdown-submenu,
  .dropdown-menu > li:hover > .dropdown-submenu-left {
  display: block;
  }
/* ART ITEM */

.app-item {

  &__wrapper {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 3px, rgba(0, 0, 0, 0.05) 0px 10px 15px -5px, rgba(0, 0, 0, 0.04) 0px 7px 7px -5px;
    border: 1px solid rgb(55, 58, 64);
  }
  
}

/* CARDS */
.app-card-intro {
  height: 300px;
  overflow: hidden;

  &__img-overlay {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }

  &__body {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  &__title {
    a {
      text-decoration: none;
    }
  }
}


/* FROM BASE TEMPLATE */


/*
Search navbar
*/
.ms-n5 {
  margin-left: -40px;
}
.search-form {
  width: 100%;

  .input-group {
    float: right !important;
    transition: all 0.35s, border-radius 0s;
    width: 32px;
    height: 32px;
    background-color: #fff;

    input.form-control {
      z-index: 1;
      border: 0 none;
    }

    &:hover, &.hover {
      width: 100%;
      border-radius: 4px 25px 25px 4px;

      .form-control {
        border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;
      }
    }
  }
}


// .search-form .input-group {
//   float: right !important;
//   transition: all 0.35s, border-radius 0s;
//   width: 32px;
//   height: 32px;
//   background-color: #fff;
// }
// .search-form .input-group input.form-control {
//   z-index: 1;
//   border: 0 none;
// }
// .search-form .form-group input.form-control::-webkit-input-placeholder {
//   display: none;
// }
// .search-form .form-group input.form-control:-moz-placeholder {
//   /* Firefox 18- */
//   display: none;
// }
// .search-form .form-group input.form-control::-moz-placeholder {
//   /* Firefox 19+ */
//   display: none;
// }
// .search-form .form-group input.form-control:-ms-input-placeholder {
//   display: none;
// }
// .search-form .input-group:hover,
// .search-form .input-group.hover {
//   width: 100%;
//   border-radius: 4px 25px 25px 4px;
// }
// //.search-form .input-group .input-group-append button,
// .search-form .input-group:hover .form-control,
// .search-form .input-group.hover .form-control {
//   border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;
// }

/* Search end */

.app-blog-list {}

.blog-post-content {
  &__title {}

  &__meta {
    margin-bottom: 1.25rem;
    color: #727272;
  }
}

.post-item {
  
  &__preview {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

}

.post-item-snap {
  margin-top: 15px;

  &__preview {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  &__body {
    width: 100%;
  }

  &__date {
    font-size: 13px;
  }

  h4 {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 5px;

    a {
      text-decoration: none;
    }
  }
}

/*
 * Footer
 */
 .blog-footer {
  padding: 2.5rem 0;
  color: #727272;
  text-align: center;
  background-color: #f9f9f9;
  border-top: .05rem solid #e5e5e5;
}
.blog-footer p:last-child {
  margin-bottom: 0;
}

